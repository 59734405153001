export const classConceptBreakdown = {
  classes: {
    "8EA" :
      {
        students: [
          {
            name: "Sarah",
            strands: {
              Algebra: {
                "Sequences and functions": {
                  "Generating sequences (2)": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Generating sequences (1)": { numberOfCorrect: 8, numberOfIncorrect: 6 },
                  "Representing simple functions": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Using the nth term": { numberOfCorrect: 5, numberOfIncorrect: 10 }
                },
                "Expressions, formulae and Equations": {
                  "Constructing Expressions": { numberOfCorrect: 5, numberOfIncorrect: 10 },
                  "Using expressions and formulae": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Collecting like terms": { numberOfCorrect: 3, numberOfIncorrect: 12 },
                  "Expanding brackets": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Constructing and solving equations": { numberOfCorrect: 2, numberOfIncorrect: 13 }
                },
                Graphs: {
                  "Graphs of functions": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Interpreting graphs": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Lines parallel to the axes": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  Functions: { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              },
              Geometry: {
                Angles: {
                  "Adjacent angles on a straight line": { numberOfCorrect: 7, numberOfIncorrect: 2 },
                  "Complementary & Supplementary angles": { numberOfCorrect: 5, numberOfIncorrect: 2 },
                  "Vertically opposite angles": { numberOfCorrect: 7, numberOfIncorrect: 0 },
                  "Angles around a point": { numberOfCorrect: 2, numberOfIncorrect: 2 }
                }
              },
              Number: {
                Integers: {
                  "Multiplying and dividing integers": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Highest common factors": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Adding and Subtracting integers": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Tests for divisibility": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Lowest common multiples": { numberOfCorrect: 9, numberOfIncorrect: 6 }
                },
                "Ratio and Proportion": {
                  "Sharing in a ratio": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Simplifying ratios": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Using direct proportions": { numberOfCorrect: 6, numberOfIncorrect: 9 }
                },
                Percentages: {
                  "Fractions decimals and percentages": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Percentages large and small": { numberOfCorrect: 14, numberOfIncorrect: 1 }
                },
                Fractions: {
                  "Making fraction calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding mixed numbers": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying fractions": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Ordering fractions": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Dividing fractions": { numberOfCorrect: 6, numberOfIncorrect: 9 }
                },
                Decimals: {
                  "Dividing decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying decimals": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Making decimal calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding and subtracting decimals": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Ordering decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 }
                }
              },
              Probability: {
                Probability: {
                  "Mutually exclusive outcomes": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Experimental probabilities": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "The probability scale": { numberOfCorrect: 9, numberOfIncorrect: 6 }
                }
              },
              Statistics: {
                "Collecting data": {
                  "Conducting an investigation": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Taking a sample": { numberOfCorrect: 9, numberOfIncorrect: 6 }
                }
              }
            }
          },
          {
            name: "Aaliyah",
            strands: {
              Algebra: {
                "Sequences and functions": {
                  "Generating sequences (2)": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Generating sequences (1)": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Representing simple functions": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Using the nth term": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                "Expressions, formulae and Equations": {
                  "Constructing Expressions": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Using expressions and formulae": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Collecting like terms": { numberOfCorrect: 4, numberOfIncorrect: 11 },
                  "Expanding brackets": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Constructing and solving equations": { numberOfCorrect: 3, numberOfIncorrect: 12 }
                },
                Graphs: {
                  "Graphs of functions": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Interpreting graphs": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Lines parallel to the axes": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  Functions: { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              },
              Geometry: {
                Angles: {
                  "Adjacent angles on a straight line": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Complementary & Supplementary angles": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Vertically opposite angles": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Angles around a point": { numberOfCorrect: 4, numberOfIncorrect: 11 }
                }
              },
              Number: {
                Integers: {
                  "Multiplying and dividing integers": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Highest common factors": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Adding and Subtracting integers": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Tests for divisibility": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Lowest common multiples": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                },
                "Ratio and Proportion": {
                  "Sharing in a ratio": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Simplifying ratios": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Using direct proportions": { numberOfCorrect: 5, numberOfIncorrect: 10 }
                },
                Percentages: {
                  "Fractions decimals and percentages": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Percentages large and small": { numberOfCorrect: 12, numberOfIncorrect: 3 }
                },
                Fractions: {
                  "Making fraction calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding mixed numbers": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Ordering fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Dividing fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Decimals: {
                  "Dividing decimals": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Multiplying decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Making decimal calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding and subtracting decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Ordering decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 }
                }
              },
              Probability: {
                Probability: {
                  "Mutually exclusive outcomes": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Experimental probabilities": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "The probability scale": { numberOfCorrect: 10, numberOfIncorrect: 5 }
                }
              },
              Statistics: {
                "Collecting data": {
                  "Conducting an investigation": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Taking a sample": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              }
            }
          },
          {
            name: "Riyaz",
            strands: {
              Algebra: {
                "Sequences and functions": {
                  "Generating sequences (2)": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Generating sequences (1)": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Representing simple functions": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Using the nth term": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                },
                "Expressions, formulae and Equations": {
                  "Constructing Expressions": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Using expressions and formulae": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Collecting like terms": { numberOfCorrect: 5, numberOfIncorrect: 10 },
                  "Expanding brackets": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Constructing and solving equations": { numberOfCorrect: 4, numberOfIncorrect: 11 }
                },
                Graphs: {
                  "Graphs of functions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Interpreting graphs": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Lines parallel to the axes": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  Functions: { numberOfCorrect: 9, numberOfIncorrect: 6 }
                }
              },
              Geometry: {
                Angles: {
                  "Adjacent angles on a straight line": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Complementary & Supplementary angles": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Vertically opposite angles": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Angles around a point": { numberOfCorrect: 3, numberOfIncorrect: 12 }
                }
              },
              Number: {
                Integers: {
                  "Multiplying and dividing integers": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Highest common factors": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Adding and Subtracting integers": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Tests for divisibility": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Lowest common multiples": { numberOfCorrect: 10, numberOfIncorrect: 5 }
                },
                "Ratio and Proportion": {
                  "Sharing in a ratio": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Simplifying ratios": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Using direct proportions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Percentages: {
                  "Fractions decimals and percentages": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Percentages large and small": { numberOfCorrect: 13, numberOfIncorrect: 2 }
                },
                Fractions: {
                  "Making fraction calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding mixed numbers": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Ordering fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Dividing fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Decimals: {
                  "Dividing decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Making decimal calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding and subtracting decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Ordering decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 }
                }
              },
              Probability: {
                Probability: {
                  "Mutually exclusive outcomes": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Experimental probabilities": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "The probability scale": { numberOfCorrect: 11, numberOfIncorrect: 4 }
                }
              },
              Statistics: {
                "Collecting data": {
                  "Conducting an investigation": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Taking a sample": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              }
            }
          },
          {
            name: "Aisha",
            strands: {
              Algebra: {
                "Sequences and functions": {
                  "Generating sequences (2)": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Generating sequences (1)": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Representing simple functions": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Using the nth term": { numberOfCorrect: 6, numberOfIncorrect: 9 }
                },
                "Expressions, formulae and Equations": {
                  "Constructing Expressions": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Using expressions and formulae": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Collecting like terms": { numberOfCorrect: 4, numberOfIncorrect: 11 },
                  "Expanding brackets": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Constructing and solving equations": { numberOfCorrect: 3, numberOfIncorrect: 12 }
                },
                Graphs: {
                  "Graphs of functions": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Interpreting graphs": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Lines parallel to the axes": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  Functions: { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              },
              Geometry: {
                Angles: {
                  "Adjacent angles on a straight line": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Complementary & Supplementary angles": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Vertically opposite angles": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Angles around a point": { numberOfCorrect: 4, numberOfIncorrect: 11 }
                }
              },
              Number: {
                Integers: {
                  "Multiplying and dividing integers": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Highest common factors": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Adding and Subtracting integers": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Tests for divisibility": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Lowest common multiples": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                },
                "Ratio and Proportion": {
                  "Sharing in a ratio": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Simplifying ratios": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Using direct proportions": { numberOfCorrect: 5, numberOfIncorrect: 10 }
                },
                Percentages: {
                  "Fractions decimals and percentages": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Percentages large and small": { numberOfCorrect: 12, numberOfIncorrect: 3 }
                },
                Fractions: {
                  "Making fraction calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding mixed numbers": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Ordering fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Dividing fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Decimals: {
                  "Dividing decimals": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Multiplying decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Making decimal calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding and subtracting decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Ordering decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 }
                }
              },
              Probability: {
                Probability: {
                  "Mutually exclusive outcomes": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Experimental probabilities": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "The probability scale": { numberOfCorrect: 10, numberOfIncorrect: 5 }
                }
              },
              Statistics: {
                "Collecting data": {
                  "Conducting an investigation": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Taking a sample": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              }
            }
          },
          {
            name: "Daniel",
            strands: {
              Algebra: {
                "Sequences and functions": {
                  "Generating sequences (2)": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Generating sequences (1)": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Representing simple functions": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Using the nth term": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                "Expressions, formulae and Equations": {
                  "Constructing Expressions": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Using expressions and formulae": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Collecting like terms": { numberOfCorrect: 5, numberOfIncorrect: 10 },
                  "Expanding brackets": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Constructing and solving equations": { numberOfCorrect: 4, numberOfIncorrect: 11 }
                },
                Graphs: {
                  "Graphs of functions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Interpreting graphs": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Lines parallel to the axes": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  Functions: { numberOfCorrect: 9, numberOfIncorrect: 6 }
                }
              },
              Geometry: {
                Angles: {
                  "Adjacent angles on a straight line": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Complementary & Supplementary angles": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Vertically opposite angles": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Angles around a point": { numberOfCorrect: 3, numberOfIncorrect: 12 }
                }
              },
              Number: {
                Integers: {
                  "Multiplying and dividing integers": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Highest common factors": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Adding and Subtracting integers": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Tests for divisibility": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Lowest common multiples": { numberOfCorrect: 10, numberOfIncorrect: 5 }
                },
                "Ratio and Proportion": {
                  "Sharing in a ratio": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Simplifying ratios": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Using direct proportions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Percentages: {
                  "Fractions decimals and percentages": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Percentages large and small": { numberOfCorrect: 13, numberOfIncorrect: 2 }
                },
                Fractions: {
                  "Making fraction calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding mixed numbers": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Ordering fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Dividing fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Decimals: {
                  "Dividing decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Making decimal calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding and subtracting decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Ordering decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 }
                }
              },
              Probability: {
                Probability: {
                  "Mutually exclusive outcomes": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Experimental probabilities": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "The probability scale": { numberOfCorrect: 11, numberOfIncorrect: 4 }
                }
              },
              Statistics: {
                "Collecting data": {
                  "Conducting an investigation": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Taking a sample": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              }
            }
          },
          {
            name: "Olivia",
            strands: {
              Algebra: {
                "Sequences and functions": {
                  "Generating sequences (2)": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Generating sequences (1)": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Representing simple functions": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Using the nth term": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                "Expressions, formulae and Equations": {
                  "Constructing Expressions": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Using expressions and formulae": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Collecting like terms": { numberOfCorrect: 5, numberOfIncorrect: 10 },
                  "Expanding brackets": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Constructing and solving equations": { numberOfCorrect: 4, numberOfIncorrect: 11 }
                },
                Graphs: {
                  "Graphs of functions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Interpreting graphs": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Lines parallel to the axes": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  Functions: { numberOfCorrect: 9, numberOfIncorrect: 6 }
                }
              },
              Geometry: {
                Angles: {
                  "Adjacent angles on a straight line": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Complementary & Supplementary angles": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Vertically opposite angles": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Angles around a point": { numberOfCorrect: 3, numberOfIncorrect: 12 }
                }
              },
              Number: {
                Integers: {
                  "Multiplying and dividing integers": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Highest common factors": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Adding and Subtracting integers": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Tests for divisibility": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Lowest common multiples": { numberOfCorrect: 10, numberOfIncorrect: 5 }
                },
                "Ratio and Proportion": {
                  "Sharing in a ratio": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Simplifying ratios": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Using direct proportions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Percentages: {
                  "Fractions decimals and percentages": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Percentages large and small": { numberOfCorrect: 13, numberOfIncorrect: 2 }
                },
                Fractions: {
                  "Making fraction calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding mixed numbers": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Ordering fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Dividing fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Decimals: {
                  "Dividing decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Making decimal calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding and subtracting decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Ordering decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 }
                }
              },
              Probability: {
                Probability: {
                  "Mutually exclusive outcomes": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Experimental probabilities": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "The probability scale": { numberOfCorrect: 11, numberOfIncorrect: 4 }
                }
              },
              Statistics: {
                "Collecting data": {
                  "Conducting an investigation": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Taking a sample": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              }
            }
          },
          {
            name: "Ethan",
            strands: {
              Algebra: {
                "Sequences and functions": {
                  "Generating sequences (2)": { numberOfCorrect: 5, numberOfIncorrect: 10 },
                  "Generating sequences (1)": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Representing simple functions": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Using the nth term": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                },
                "Expressions, formulae and Equations": {
                  "Constructing Expressions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Using expressions and formulae": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Collecting like terms": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Expanding brackets": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Constructing and solving equations": { numberOfCorrect: 4, numberOfIncorrect: 11 }
                },
                Graphs: {
                  "Graphs of functions": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Interpreting graphs": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Lines parallel to the axes": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  Functions: { numberOfCorrect: 9, numberOfIncorrect: 6 }
                }
              },
              Geometry: {
                Angles: {
                  "Adjacent angles on a straight line": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Complementary & Supplementary angles": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Vertically opposite angles": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Angles around a point": { numberOfCorrect: 3, numberOfIncorrect: 12 }
                }
              },
              Number: {
                Integers: {
                  "Multiplying and dividing integers": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Highest common factors": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Adding and Subtracting integers": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Tests for divisibility": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Lowest common multiples": { numberOfCorrect: 10, numberOfIncorrect: 5 }
                },
                "Ratio and Proportion": {
                  "Sharing in a ratio": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Simplifying ratios": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Using direct proportions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Percentages: {
                  "Fractions decimals and percentages": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Percentages large and small": { numberOfCorrect: 13, numberOfIncorrect: 2 }
                },
                Fractions: {
                  "Making fraction calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding mixed numbers": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Ordering fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Dividing fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Decimals: {
                  "Dividing decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Making decimal calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding and subtracting decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Ordering decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 }
                }
              },
              Probability: {
                Probability: {
                  "Mutually exclusive outcomes": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Experimental probabilities": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "The probability scale": { numberOfCorrect: 11, numberOfIncorrect: 4 }
                }
              },
              Statistics: {
                "Collecting data": {
                  "Conducting an investigation": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Taking a sample": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              }
            }
          },
          {
            name: "Sophia",
            strands: {
              Algebra: {
                "Sequences and functions": {
                  "Generating sequences (2)": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Generating sequences (1)": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  "Representing simple functions": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Using the nth term": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                "Expressions, formulae and Equations": {
                  "Constructing Expressions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Using expressions and formulae": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Collecting like terms": { numberOfCorrect: 5, numberOfIncorrect: 10 },
                  "Expanding brackets": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Constructing and solving equations": { numberOfCorrect: 4, numberOfIncorrect: 11 }
                },
                Graphs: {
                  "Graphs of functions": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Interpreting graphs": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Lines parallel to the axes": { numberOfCorrect: 9, numberOfIncorrect: 6 },
                  Functions: { numberOfCorrect: 9, numberOfIncorrect: 6 }
                }
              },
              Geometry: {
                Angles: {
                  "Adjacent angles on a straight line": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Complementary & Supplementary angles": { numberOfCorrect: 6, numberOfIncorrect: 9 },
                  "Vertically opposite angles": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Angles around a point": { numberOfCorrect: 3, numberOfIncorrect: 12 }
                }
              },
              Number: {
                Integers: {
                  "Multiplying and dividing integers": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Highest common factors": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "Adding and Subtracting integers": { numberOfCorrect: 12, numberOfIncorrect: 3 },
                  "Tests for divisibility": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Lowest common multiples": { numberOfCorrect: 10, numberOfIncorrect: 5 }
                },
                "Ratio and Proportion": {
                  "Sharing in a ratio": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Simplifying ratios": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Using direct proportions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Percentages: {
                  "Fractions decimals and percentages": { numberOfCorrect: 13, numberOfIncorrect: 2 },
                  "Percentages large and small": { numberOfCorrect: 13, numberOfIncorrect: 2 }
                },
                Fractions: {
                  "Making fraction calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding mixed numbers": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Ordering fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 },
                  "Dividing fractions": { numberOfCorrect: 7, numberOfIncorrect: 8 }
                },
                Decimals: {
                  "Dividing decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Multiplying decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Making decimal calculations easier": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Adding and subtracting decimals": { numberOfCorrect: 10, numberOfIncorrect: 5 },
                  "Ordering decimals": { numberOfCorrect: 14, numberOfIncorrect: 1 }
                }
              },
              Probability: {
                Probability: {
                  "Mutually exclusive outcomes": { numberOfCorrect: 8, numberOfIncorrect: 7 },
                  "Experimental probabilities": { numberOfCorrect: 11, numberOfIncorrect: 4 },
                  "The probability scale": { numberOfCorrect: 11, numberOfIncorrect: 4 }
                }
              },
              Statistics: {
                "Collecting data": {
                  "Conducting an investigation": { numberOfCorrect: 14, numberOfIncorrect: 1 },
                  "Taking a sample": { numberOfCorrect: 8, numberOfIncorrect: 7 }
                }
              }
            }
          },
        ]
      }
}
};
