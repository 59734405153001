import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";
import { UserOutlined, BookOutlined, SettingOutlined } from '@ant-design/icons';
import { classConceptBreakdown } from '../../../MockData/mockSchoolData'; // Import the class mock data

const { Option } = Select;

const ClassDashboardFilters = ({ selectedClass, selectedStrand, selectedUnit, onFilterChange }) => {
  // eslint-disable-next-line no-unused-vars
  const [availableClasses, setAvailableClasses] = useState(["8EA", "8EB"]); // Example classes
  const [strands, setStrands] = useState([]);
  const [units, setUnits] = useState([]);

  useEffect(() => {
    // Update strands and units based on selected class
    const classData = classConceptBreakdown.classes[selectedClass] || classConceptBreakdown.classes["8EA"]; // Default to "8EA" data
    console.log("Selected Class Data:", classData);

    if (!classData || !classData.students || classData.students.length === 0) {
      console.warn(`No students found for class "${selectedClass}".`);
      setStrands([]);
      setUnits([]);
      return;
    }

    const newStrands = Object.keys(classData.students[0].strands);
    setStrands(newStrands);

    // Update units whenever a strand is selected
    if (selectedStrand) {
      const strandData = classData.students[0].strands[selectedStrand];
      if (strandData) {
        const newUnits = Object.keys(strandData);
        setUnits(newUnits);
      } else {
        console.warn(`Strand "${selectedStrand}" not found in class "${selectedClass}".`);
        setUnits([]);
      }
    } else {
      setUnits([]);
    }
  }, [selectedClass, selectedStrand]);

  const handleClassChange = (value) => {
    onFilterChange({ class: value, strand: "", unit: "" });
  };

  const handleStrandChange = (value) => {
    onFilterChange({ strand: value || "", unit: "" });
  };

  const handleUnitChange = (value) => {
    onFilterChange({ unit: value || "" });
  };

  return (
    <Form className="filter-container">
      <Form.Item label={<span className="filter-label"><UserOutlined /> Class</span>}>
        <Select
          placeholder="Select Class"
          value={selectedClass || undefined}
          onChange={handleClassChange}
          style={{ minWidth: 180 }}
          allowClear
        >
          {availableClasses.map((cls) => (
            <Option key={cls} value={cls}>
              {cls}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={<span className="filter-label"><BookOutlined /> Strand</span>}>
        <Select
          placeholder="Select Strand"
          value={selectedStrand || undefined}
          onChange={handleStrandChange}
          style={{ minWidth: 180 }}
          disabled={!selectedClass}
          allowClear
        >
          {strands.map((strand) => (
            <Option key={strand} value={strand}>
              {strand}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={<span className="filter-label"><SettingOutlined /> Unit</span>}>
        <Select
          placeholder="Select Unit"
          value={selectedUnit || undefined}
          onChange={handleUnitChange}
          style={{ minWidth: 180 }}
          disabled={!selectedStrand}
          allowClear
        >
          {units.map((unit) => (
            <Option key={unit} value={unit}>
              {unit}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default ClassDashboardFilters;