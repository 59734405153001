export const calculateClassConceptAveragesCalculator = (data, selectedStrand, selectedUnit) => {
  // Ensure data and its structure are present
  console.log("calculateClassConceptAveragesCalculator called with:", {
    data, selectedStrand, selectedUnit
  });
  if (!data || !data.students) {
    console.warn(`Expected class data with students, but received:`, data);
    return { labels: [], data: [] };
  }

  const classConceptAverages = {};
  let studentCount = 0;

  data.students.forEach(student => {
    const strandData = student.strands[selectedStrand];
    if (strandData && strandData[selectedUnit]) {
      studentCount++;
      const unitData = strandData[selectedUnit];

      Object.keys(unitData).forEach(concept => {
        const { numberOfCorrect, numberOfIncorrect } = unitData[concept];
        const score = (numberOfCorrect / (numberOfCorrect + numberOfIncorrect)) * 100;
        classConceptAverages[concept] = (classConceptAverages[concept] || 0) + score;
      });
    }
  });

  // Calculate averages
  const averagedConceptScores = {};
  Object.keys(classConceptAverages).forEach(concept => {
    averagedConceptScores[concept] = studentCount > 0 ? classConceptAverages[concept] / studentCount : 0;
  });

  return {
    labels: Object.keys(averagedConceptScores),
    data: Object.values(averagedConceptScores),
  };
};



export const mapStudentDistribution = (classData, strand, unit) => {
  const distribution = { aboveLevel: 0, atLevel: 0, belowLevel: 0 };
  console.log(classData)
  classData.students.forEach((student) => {
    const strands = strand ? { [strand]: student.strands[strand] } : student.strands;

    Object.values(strands).forEach((strandData) => {
      const units = unit ? { [unit]: strandData[unit] } : strandData;

      Object.values(units).forEach((concepts) => {
        Object.values(concepts).forEach(({ numberOfCorrect, numberOfIncorrect }) => {
          const percentage = (numberOfCorrect / (numberOfCorrect + numberOfIncorrect)) * 100;
          if (percentage >= 70) distribution.aboveLevel++;
          else if (percentage >= 50) distribution.atLevel++;
          else distribution.belowLevel++;
        });
      });
    });
  });

  return [distribution.aboveLevel, distribution.atLevel, distribution.belowLevel];
};


export const mapQuizAttempts = (classData, strand, unit) => {
  return classData.students.map(student => {
    const strands = strand ? { [strand]: student.strands[strand] } : student.strands;

    return Object.values(strands).reduce((total, strandData) => {
      const units = unit ? { [unit]: strandData[unit] } : strandData;
      return total + Object.values(units).reduce((acc, concepts) => {
        return acc + Object.values(concepts).reduce((sum, { numberOfCorrect }) => sum + numberOfCorrect, 0);
      }, 0);
    }, 0);
  });
};


export const mapPerformanceOverTime = (classData, strand, unit) => {
  const trendData = [];

  classData.students.forEach(student => {
    const strands = strand ? { [strand]: student.strands[strand] } : student.strands;

    trendData.push(Object.values(strands).reduce((total, strandData) => {
      const units = unit ? { [unit]: strandData[unit] } : strandData;
      return total + Object.values(units).reduce((acc, concepts) => {
        return acc + Object.values(concepts).reduce((sum, { numberOfCorrect }) => sum + numberOfCorrect, 0);
      }, 0);
    }, 0));
  });

  return trendData;
};


export const mapTopPerformers = (classData, strand, unit) => {
  console.log(classData)
  const studentScores = classData.students.map(student => {
    const strands = strand ? { [strand]: student.strands[strand] } : student.strands;

    const score = Object.values(strands).reduce((total, strandData) => {
      const units = unit ? { [unit]: strandData[unit] } : strandData;
      return total + Object.values(units).reduce((acc, concepts) => {
        return acc + Object.values(concepts).reduce((sum, { numberOfCorrect }) => sum + numberOfCorrect, 0);
      }, 0);
    }, 0);

    return { name: student.name, score };
  });
  console.log(studentScores)
  return studentScores.sort((a, b) => b.score - a.score).slice(0, 3);
};

export const mapUnderPerformers = (classData, strand, unit) => {
  const studentScores = classData.students.map(student => {
    const strands = strand ? { [strand]: student.strands[strand] } : student.strands;

    const score = Object.values(strands).reduce((total, strandData) => {
      const units = unit ? { [unit]: strandData[unit] } : strandData;
      return total + Object.values(units).reduce((acc, concepts) => {
        return acc + Object.values(concepts).reduce((sum, { numberOfCorrect }) => sum + numberOfCorrect, 0);
      }, 0);
    }, 0);

    return { name: student.name, score };
  });
  console.log(studentScores)
  return studentScores.sort((a, b) => a.score - b.score).slice(0, 3);
};