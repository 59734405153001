import Chart from 'react-apexcharts';

const TotalStudentDistribution = ({ data }) => {
  const chartOptions = {
    labels: ["Above level", "At level", "Below level"],
    colors: ["#4CAF50", "#FFC107", "#F44336"]
  };

  return (
    <div className="chart-container">
      <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Student Level Overview</p>
      <Chart series={data} options={chartOptions} type="donut" width={400} />
    </div>
  );
};

export default TotalStudentDistribution;