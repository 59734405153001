import React from 'react';
import ConceptRadarChartTemplateApex from '../../ConceptRadarCharts/ConceptRadarChartTemplateApex';

const ClassConceptStrengthChart = ({ data }) => {
  console.log("ClassConceptStrengthChart received data:", data);

  if (!data || data.labels.length === 0) {
    return <div className="empty-message">Please select a strand and unit to display the chart.</div>;
  }

  return (
    <div className='radarchartContainerCS'>
      <h3>Class Concept Strength</h3>
      <ConceptRadarChartTemplateApex
        labels={data.labels}
        data={data.data}
      />
    </div>
  );
};

export default ClassConceptStrengthChart;