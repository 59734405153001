import { useEffect, useState } from 'react';
import { Card, Space, Tabs } from 'antd';
import ClassRoomOverview from './ClassRoomOverview';
import QuizAttemptSummary from './QuizAttemptSummary';
import TotalStudentDistribution from './TotalStudentDistribution';
import PerformanceOverTime from './PerformanceOverTime';
import TopPerformersChart from './TopPerformersChart';
import UnderPerformersChart from './UnderPerformersChart';
import ClassConceptStrengthChart from './ClassConceptStrengthChart';
import ClassDashboardFilters from './ClassDashboardFilters';
import ClassRoomHeader from './ClassRoomHeader';
import "./styles.css";
import {
  calculateClassConceptAveragesCalculator,
  mapPerformanceOverTime,
  mapQuizAttempts, mapStudentDistribution,
  mapTopPerformers, mapUnderPerformers
} from '../utils/calculateClassConceptAveragesCalculator';
import { classConceptBreakdown } from '../../../MockData/mockSchoolData';

const { TabPane } = Tabs;

const ClassDashboardView = () => {
  const [filters, setFilters] = useState({ class: "8EA", strand: "", unit: "" });
  const [chartData, setChartData] = useState({
    conceptRadar: null,
    studentDistribution: [],
    quizAttempts: [],
    performanceOverTime: [],
    topPerformers: [],
    underPerformers: []
  });

  const handleFilterChange = (newFilters) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters
    }));
  };

  useEffect(() => {
    const selectedClassData = classConceptBreakdown.classes[filters.class];
    console.log("Filters:", filters);
    console.log("Selected Class Data:", selectedClassData);

    if (!selectedClassData) {
      console.warn(`Data for class "${filters.class}" not found or undefined.`);
      return;
    }

    // Calculate concept radar data only if strand and unit are selected
    let conceptRadarData = null;
    if (filters.strand && filters.unit) {
      conceptRadarData = calculateClassConceptAveragesCalculator(
        selectedClassData,
        filters.strand,
        filters.unit
      );
      console.log('Calculated Radar Data:', conceptRadarData);
    }

    // Process data for other charts
    const studentDistributionData = mapStudentDistribution(selectedClassData, filters.strand, filters.unit);
    const quizAttemptData = mapQuizAttempts(selectedClassData, filters.strand, filters.unit);
    const performanceData = mapPerformanceOverTime(selectedClassData, filters.strand, filters.unit);
    const topPerformersData = mapTopPerformers(selectedClassData, filters.strand, filters.unit);
    const underPerformersData = mapUnderPerformers(selectedClassData, filters.strand, filters.unit);

    console.log("Calculated Other Chart Data:", {
      studentDistributionData,
      quizAttemptData,
      performanceData,
      topPerformersData,
      underPerformersData
    });

    // Update chartData in a single state update
    setChartData({
      conceptRadar: conceptRadarData,
      studentDistribution: studentDistributionData,
      quizAttempts: quizAttemptData,
      performanceOverTime: performanceData,
      topPerformers: topPerformersData,
      underPerformers: underPerformersData
    });

  }, [filters.class, filters.strand, filters.unit]);

  useEffect(() => {
    console.log("Chart Data Updated:", chartData);
  }, [chartData]);

  return (
    <div style={{ padding: '24px', backgroundColor: '#ffffff' }}>
      <ClassRoomHeader />
      <div className="sticky-dashboard-filters">
        <Card className="filter-card" bordered={false}>
          <ClassDashboardFilters
            selectedClass={filters.class}
            selectedStrand={filters.strand}
            selectedUnit={filters.unit}
            onFilterChange={handleFilterChange}
          />
        </Card>
      </div>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Card>
          <ClassRoomOverview filters={filters} />
        </Card>
        <div className="overview-container">
          {/* Performers Tabs */}
          <div className="chart-container">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Top Performers" key="1">
                <TopPerformersChart data={chartData.topPerformers} />
              </TabPane>
              <TabPane tab="Under Performers" key="2">
                <UnderPerformersChart data={chartData.underPerformers} />
              </TabPane>
            </Tabs>
          </div>
          {/* Class Concept Strength Radar Chart */}
          <div className="chart-container-radar">
            {chartData.conceptRadar ? (
              <ClassConceptStrengthChart data={chartData.conceptRadar} />
            ) : (
              <div className="empty-message">Please select a strand and unit to display the chart.</div>
            )}
          </div>

          <div className="chart-container">
            <TotalStudentDistribution data={chartData.studentDistribution} />
          </div>
          <div className="chart-container">
            <QuizAttemptSummary data={chartData.quizAttempts} />
          </div>
          <div className="chart-container">
            <PerformanceOverTime data={chartData.performanceOverTime} />
          </div>
        </div>
      </Space>
    </div>
  );
};

export default ClassDashboardView;