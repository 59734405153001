import Chart from 'react-apexcharts';

const PerformanceOverTime = ({ data }) => {
  const chartOptions = {
    chart: { id: "performance-line" },
    xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May'] },
    colors: ['#00E396']
  };

  return (
    <div className="chart-container">
      <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Performance Over Time</p>
      <Chart options={chartOptions} series={[{ name: "Score", data }]} type="line" width={400} />
    </div>
  );
};

export default PerformanceOverTime;
