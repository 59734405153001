import React from 'react';
import Chart from 'react-apexcharts';

// Utility function to generate shades of green
const generateGreenShades = (numberOfShades) => {
  const greenColors = [
    '#006400', // DarkGreen
    '#228B22', // ForestGreen
    '#32CD32', // LimeGreen
    '#66CDAA', // MediumAquamarine
    '#90EE90'  // LightGreen
  ];
  return greenColors.slice(0, numberOfShades);
};

const TopPerformersChart = ({ data }) => {
  // Generate green colors based on number of performers
  const greenColors = generateGreenShades(data.length);

  const chartOptions = {
    chart: { type: 'bar' },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true, // Enables individual bar colors
        dataLabels: {
          position: 'top', // Position data labels on top of bars
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    xaxis: {
      categories: data.map(item => item.name),
      title: {
        text: 'Scores',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#333'
        }
      },
      labels: {
        style: {
          fontSize: '12px',
          colors: ['#333']
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          colors: ['#333']
        }
      }
    },
    colors: greenColors, // Assign dynamically generated green colors
    tooltip: {
      theme: 'dark'
    }
  };

  const series = [{ name: "Score", data: data.map(item => item.score) }];

  return (
    <div>
      <p>Top {data.length} Performers</p>
      <Chart options={chartOptions} series={series} type="bar" width="100%" />
    </div>
  );
};

export default TopPerformersChart;