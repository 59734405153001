import React, { useState, useEffect } from 'react';
import './styles.css'; // Ensure this path points to your CSS file

const ClassRoomHeader = () => {
  const schoolName = "Zahira College Colombo";
  const [currentDateTime, setCurrentDateTime] = useState(getFormattedDateTime());

  // Function to get the current date and time formatted as "Weekday, Month Day, Year | HH:MM AM/PM"
  function getFormattedDateTime() {
    const now = new Date();

    // Options for date formatting
    const dateOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    // Format date
    const formattedDate = now.toLocaleDateString(undefined, dateOptions);

    // Format time without seconds
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;

    return `${formattedDate} | ${formattedTime}`;
  }

  useEffect(() => {
    // Update the date and time every minute
    const timer = setInterval(() => {
      setCurrentDateTime(getFormattedDateTime());
    }, 60000); // 60000ms = 1 minute

    // Initial call to set the correct time immediately
    setCurrentDateTime(getFormattedDateTime());

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="class-room-header">
      <h1 className="header-school-name">{schoolName}</h1>
      <div className="header-date-time">{currentDateTime}</div>
    </div>
  );
}

export default ClassRoomHeader;