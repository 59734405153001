import { TeamOutlined, ProfileOutlined, FileDoneOutlined } from '@ant-design/icons';

const ClassRoomOverview = () => {
  const studentCount = 45;
  const quizzesAssignedCount = 10;
  const unitsCoveredCount = 4;

  const overviewCard = (icon, value, title, color) => (
    <div className="classroom-overview-card">
      <div className="icon-container" style={{ color }}>{icon}</div>
      <div className="count-and-label">
        <div className="classroom-overview-value">{value}</div>
        <div className="classroom-overview-title">{title}</div>
      </div>
    </div>
  );

  return (
    <div className="overview-block">
      {overviewCard(<TeamOutlined />, studentCount, 'Students', '#4E89FF')}
      {overviewCard(<ProfileOutlined />, quizzesAssignedCount, 'Quizzes', '#FF0099')}
      {overviewCard(<FileDoneOutlined />, unitsCoveredCount, 'Units Covered', '#BA06B4')}
    </div>
  );
};

export default ClassRoomOverview;