import Chart from 'react-apexcharts';

const QuizAttemptSummary = ({ data }) => {
  const chartOptions = {
    chart: { id: "basic-bar" },
    xaxis: { categories: ['Quiz 1', 'Quiz 2', 'Quiz 3', 'Quiz 4', 'Quiz 5', 'Quiz 6', 'Quiz 7', 'Quiz 8'] },
    colors: ['#008FFB']
  };

  return (
    <div className="chart-container">
      <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Quiz Attempt Summary</p>
      <Chart options={chartOptions} series={[{ name: "Students", data }]} type="bar" width={400} />
    </div>
  );
};

export default QuizAttemptSummary;